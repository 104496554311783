import { titleize, capitalize } from './StringUtils'
import { flatten } from './CollectionUtils'

export function JSONApiResourceDecorator(endpoint, {responseOnly=false, noParams=false} = {}){
  if(!responseOnly){
    endpoint.paramsHandler(encodeJSONApiParams)
  }
  if(noParams){
    endpoint.paramsHandler(() => undefined)
  }
  endpoint.resultsHandler(decodeJSONApiResponse)
          .errorHandler(decodeJSONApiError)
  return endpoint
}

export function encodeJSONApiParams(combinedAttributes){
  if(!combinedAttributes){
    return null
  }
  const { options, ...attributes } = combinedAttributes
  const extractItem = ({id, type, relationships, destroy, ...attributes}) => {
    let encodedRelationships = Object.keys(relationships || {}).reduce((acc, key) => {
      const { data } = relationships[key]
      acc[key] = { data: Array.isArray(data) ? data.map(extractItem) : extractItem(data) }
      return acc
    }, {})

    return ({
      id,
      type,
      attributes,
      ...(!!relationships) && {relationships: encodedRelationships},
      ...(!!destroy) && {meta: {'_destroy': destroy}}
    })
  } 

  return {
    data: Array.isArray(attributes.attributes) ?
      attributes.attributes.map(extractItem) :
      extractItem(attributes),
    ...options
  }
}

export function decodeJSONApiResponse(response, options = {}) {
  let { method } = options
  let { meta } = response
  if(method === 'delete') {
    // see http://jsonapi.org/format/#crud-deleting
    return { meta }
  }

  let { data: result, included, errors } = response

  // Handle 204 No Content response
  if (errors && errors.some(e => e.status === 204)) {
    return {}
  }

  if (result === null || result === undefined) {
    if (meta === null || meta === undefined) {
      throw new Error('result should not be null or undefined')
    } else {
      return { meta, ...(included ? {included} : {}) }
    }
  }

  if (Array.isArray(result)) {
    return {data: result.map(r => decodeJSONApiResponse({data: r, included}).data), meta}
  } else {
    const { id, type, attributes, relationships } = result || {}
    const decoded = {
      data: {
        id, type, ...attributes
      }, meta
    }
    if(relationships){
      fillRelationships(decoded, relationships, buildIncludedMap(included))
    }
    return decoded
  }
}

function buildIncludedMap(included){
  const includedMap = {}
  included = included || []
  included.forEach(included => {
    includedMap[`${included.id}.${included.type}`] = {id: included.id, type: included.type, ...included.attributes }
  })
  included.forEach(({id, type, relationships}) => {
    if(relationships && Object.entries(relationships).length){
      const object = includedMap[`${id}.${type}`]
      Object.entries(relationships).forEach(([relationship, {data}]) => {
        if(!data)
          return
        const extractObject = ({id, type}) => includedMap[`${id}.${type}`]
        object[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
      })
    }
  })
  return includedMap
}

function fillRelationships(decoded, relationships, included){
  Object.entries(relationships).forEach(([relationship, {data}]) => {
    const extractObject = ({id, type, relationships}) => included[`${id}.${type}`]
    if(data) {
      decoded.data[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
    }
  })
  return decoded
}

export function decodeJSONApiError(errorResponse){
  if(errorResponse && errorResponse.body && errorResponse.body.errors){
    const firstError = {...errorResponse.body.errors[0]}
    firstError.additionalErrors = errorResponse.body.errors
    return firstError
  }
  return errorResponse
}

export function errorStringsFromError(error){
  return rawErrorStringsFromJSONApiError(error)
}

export function rawErrorStringsFromJSONApiError(error){
  if(typeof error === 'string'){
    return [error]
  }
  if(Array.isArray(error)){
    return flatten(error.map(rawErrorStringsFromJSONApiError))
  }
  const { meta={}, title='', message='' } = error || {}
  return [`${title}${(title && message && title !== message) ? ': ' : ''}${title !== message ? message : ''}`].concat(
    Object.entries(meta).map(([key, values]) => `${titleize(key)}: ${capitalize(values.join(', '))}`)
  ).filter(x => !!x)
}

