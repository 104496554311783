import React, { Component } from 'react'
import { ControlledForm, DatePicker, DateTimePicker, TimePicker }      from 'components'
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export class Demo extends Component{

  state = {
    form: {
      author: {
        firstName: 'J.R.R',
        lastName: 'Tolkien',
        active: true,
        gender: 'male',
        numBooks: 3,
        books: [
          {name: 'Fellowship of the Ring'},
          {name: 'The Two Towers'},
          {name: 'Return of the King'}
        ]
      },
      totalEarnings: {
        usd: 7000
      },
      copyrightExpirationDate: '2018-01-01',
      publishDate: '2018-01-01',
      releaseDate: '2018-01-01',
      presaleCutoffTime: '2001-01-01T12:00',
      preferredContactMethod: 'phone'
    },
    errors: {}
  }

  handleChange = form => {
    countBooks(form)
    this.setState({form})
  }

  render = () =>
    <ControlledForm
      onChange={this.handleChange}
      data={this.state.form}
      errors={this.state.errors}
    >
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', padding: 10}}>

          <h3>Styled Inputs:</h3>
          <TextField name='author.firstName'/>
          <TextField name='author.lastName'/>
          <TextField name='copyrightExpirationDate' type='date' />

          <DatePicker name='publishDate'  />
          <DateTimePicker name='releaseDate'  />
          <TimePicker name='presaleCutoffTime'/>

          <FormControlLabel control={
            <ControlledForm onChange={this.handleChange} data={this.state.form}>
              <Checkbox type='checkbox' name='author.active'/>
            </ControlledForm>} label="Active"
          />

          <input name='author.active' type='checkbox' />

          <RadioGroup name="author.gender">
            <FormControlLabel value="female" control={<Radio/>} label="Female" />
            <FormControlLabel value="male"   control={<Radio/>} label="Male" />
            <FormControlLabel value="other"  control={<Radio/>} label="Other" />
          </RadioGroup>

          <h3>Nested Contexts:</h3>
          <ControlledForm name='totalEarnings'>
            <TextField name='usd' type='number' label='USD(millions)' />
          </ControlledForm>

          <h3>Vanilla Inputs:</h3>
          <ControlledForm name='author'>
            <input name='firstName' type='text' />
            <input name='lastName'  type='text' />
          </ControlledForm>

          <h3>Books:</h3>
          <TextField type='number' name='author.numBooks'/>
          <ControlledForm name='author.books'>
            {
              this.state.form.author.books.map((book, i) =>
                <TextField key={i} name={`[${i}].name`}/>
              )
            }
          </ControlledForm>
        </div>

        <div style={{flex: 1, background: '#efefef', border: '1px solid silver', margin: 10, padding: 5}}>
          <h3>Preview:</h3>
          <pre style={{fontFamily: 'courier'}}>{JSON.stringify(this.state, null, 2)}</pre>
        </div>
      </div>
    </ControlledForm>
}

const countBooks = state => {
  state.author.numBooks  = Math.max(state.author.numBooks, 0)
  state.author.books     = [...state.author.books, ...new Array(state.author.numBooks || 0).fill({name: ''})].slice(0, state.author.numBooks)
  return state
}
export default Demo