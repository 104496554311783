import React, { Component } from 'react'
import StatusPageMixin from './StatusPageMixin'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

export class NotFound extends StatusPageMixin(Component){
  body(){
    return (
      <div>
        <Typography variant='h5'>Uh-oh!</Typography>
        <Typography variant='body1'> We couldn't find what you are looking for.</Typography>
        <br/>
        <Button fullWidth variant='contained' color='secondary' onClick={this.goBack}>Go Back!</Button>
      </div>
    )
  }
}

export default (NotFound)