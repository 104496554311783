import React from 'react'
import * as Containers from 'containers'

import { MuiThemeProvider }        from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils                 from '@date-io/moment';
import { Router }                  from 'react-router-dom';
import { history }                 from 'utils';
import { theme }                   from 'styles'

export const AppContainer = () =>
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <Containers.App/>
      </MuiThemeProvider>
    </Router>
  </MuiPickersUtilsProvider>

export default AppContainer