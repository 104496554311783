import React, { Component } from 'react'
import instanceForm    from 'containers/shared/instanceForm'
import dependsOn            from 'containers/shared/dependsOn'
import {ErrorBanner, ControlledForm, PageContainer, Select} from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { compose } from 'utils'
import withStyles from 'styles'
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Authorization } from 'utils'
import { provide, UsersContext }          from 'contexts'
import Box from '@material-ui/core/Box'

export class Form extends Component {

  render = () => {
    const {formData, onFormDataChange, onSave, errors, editMode, errorStrings} = this.props
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>Edit User - {formData.name}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='name'/>
          <TextField fullWidth name='email'/>
          <Select disabled={this.editMode && !Authorization.admin} fullWidth name='role'>
            <MenuItem value='member'>Member</MenuItem>
            <MenuItem value='admin'>Admin</MenuItem>
          </Select>
          {
            (!editMode) &&
            <FormControlLabel control={
              <ControlledForm onChange={onFormDataChange} data={formData}>
                <Checkbox name='skipInvite'/>
              </ControlledForm>} label="Skip Invite"
            />
          }
          <br/>
          {
            ((editMode && Authorization.user.id === this.props.users.selected.id) || formData.skipInvite) &&
            <Box p={2} bgcolor="background.default">
              <Typography variant='body1'>Password {editMode && '(Optional)'}</Typography>
              {
                Authorization.user.id === this.props.users.selected.id &&
                <TextField color='background.paper'  type="password" fullWidth name="oldPassword"/>
              }
              <TextField color='background.paper' type='password' fullWidth name='password'/>
              <TextField color='background.paper' disabled={!formData.password} type='password' fullWidth name='passwordConfirmation'/>
            </Box>
          }

          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </ControlledForm>
      </PageContainer>
    )
  }
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
})

const fetchDependencies = ({users, id, editMode}) => {
  if(editMode){
    return users.actions.show(id)
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('users'),
  withStyles(styles),
  provide(UsersContext),
)(Form)