import React from 'react'
import { capitalize } from 'utils'
import hoistNonReactStatic from 'hoist-non-react-statics';

export const provide = (...contexts) => Component =>
  contexts.reduce((component, context) => connectSingle(context, true)(component), Component)

export const consume = (...contexts) => Component =>
  contexts.reduce((component, context) => connectSingle(context, false)(component), Component)

export const namespacedProvide = (namespace, context) => Component => {
  if (typeof namespace !== 'string' || !namespace.length) throw new Error("namespacedProvide Expected namespace as first argument")
  return connectSingle(context, true, namespace)(Component)
}

export const namespacedConsume = (namespace, context) => Component =>{
  if (typeof namespace !== 'string' || !namespace.length) throw new Error("namespacedProvide Expected namespace as first argument")
  return connectSingle(context, false, namespace)(Component)
}

export const connectSingle = (Context, provide, namespace=Context.contextName) => Component => {
  const connectedSingle = props => {
    const consumer = (
      <Context.Consumer>
        {
          value =>  {
            const merged = namespace ? {[namespace]: value} : value
            if(namespace && value === null){
              throw new Error(`Error rendering ${Component.name}. Attempted to consume: ${namespace} context but there is no surrounding provide().`)
            }
            return <Component {...merged} {...props}/>
          }
        }
      </Context.Consumer>
    )
    return provide ? <Context>{consumer}</Context> : consumer
  }
  connectedSingle.displayName = `Connected(${capitalize(Context.name || 'Context')})`
  hoistNonReactStatic(connectedSingle, Component)
  return connectedSingle
}